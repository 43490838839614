
@font-face {
    font-family: "Tondo Corp", "Open Sans", Helvetica, sans-serif;
    font-display: swap;
    src: url('/fonts/tondo-corp/TondoCorp-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/tondo-corp/TondoCorp-Regular.woff') format('woff'),
    url('/fonts/tondo-corp/TondoCorp-Regular.ttf') format('truetype'),
    url('/fonts/tondo-corp/TondoCorp-Regular.svg') format('svg');
}

.ant-image-preview-root {
    display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
